import style from '../styles/MessageBox.module.scss'
import Text from './LanguageResolver';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

export default function MessageBox () {

    const [_, setRerender] = useState(0);
    const rerenderCounter = useRef(0);
    const messages = useRef([]);

    // show message in the message box
    function show(value, type) {
        messages.current = [...messages.current, {msg: value, type: type}];
        setRerender(++rerenderCounter.current);
    }
    // supply the show function to the global scope with typescript declaration
    useEffect(() => {
        window.showMessage = show;
    }, []);

    return (
        <div id="message_box" className={style.container}>
            {messages.current.map((msg, i) => {
                return (
                    <Message key={i} val={msg.msg} type={msg.type} />
                );
            })}
        </div>
    );
}

function Message({ val, type }) {
    return (
        <div className={type == 'success' ? style.success : type == 'error' ? style.error : style.info}>
            <Text val={val}/>
        </div>
    );
}