
import { useContext } from 'react';
import { UserData } from './Context';
import en from '@/lang/en.json';
import de from '@/lang/de.json';
import pl from '@/lang/pl.json';

export default function Text({ val } : {val: string}) {

    const { user, _ } = useContext(UserData);

    const value = user.language.values[val];
    return value ?? val;
}

export function changeLanguage(lang: "en" | "de" | "pl", user: any, setUser: any) {
    setUser({ ...user, language: lang === "de" ? de : (lang === "en" ? en : pl) });

    // send language change to server
    fetch('/api/user/changeLanguage?newLanguage=' + lang, {
        method: 'GET'
    });
}