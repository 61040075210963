import { createContext, useState } from "react";
import { useEffect } from "react";
import en from "@/lang/en.json";
import de from "@/lang/de.json";
import pl from "@/lang/pl.json";

export const UserData = createContext<any>(  {
    user: {
      isLoaded: false, 
      triedLoading: false, 
      reload: true,
      language: en,
    },
    setUser: (user: any) => {}
  });

export default function Context({ children }) {

    const [user, setUser] = useState<any>({
      isLoaded: false, 
      triedLoading: false, 
      reload: true,
      language: en
    });

    useEffect(() => {
      fetch("/api/user/profile", {
        method: 'GET',
        }).then((res) => {
          if (res.status != 200) {
            setUser({
              ...user,
              isLoaded: false,
              triedLoading: true
            });
          } else {
            res.json().then((data: any) => {
              if (data.status == 200) {
                setUser({
                  ...user,
                  ...data,
                  isLoaded: true,
                  triedLoading: true, 
                  reload: user.reload,
                  language: data.preferredLanguage === undefined ? user.language : data.preferredLanguage === 'de' ? de : (data.preferredLanguage === 'en' ? en : pl)
                });
              }
            });
          }
        });
    }, [user.reload]);

    return (
      <UserData.Provider value={{ user, setUser }}>
        {children}
      </UserData.Provider>
    );
  }

export function reloadUser(user, setUser, hard = false) {
  setUser({...user, isLoaded: hard, triedLoading: hard, reload: !user.reload});
}

export function requireLogin(user, Router) {
  if (user.triedLoading && !user.isLoaded) {
    Router.push('/user/login?redirect=' + Router.asPath);
    (window as any).showMessage('message.pleaselogin', 'error');
  }
}