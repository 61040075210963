import Navbar from '@/components/Navbar'
import '@/styles/globals.scss'
import Context from '@/components/Context'
import MessageBox from '@/components/MessageBox'
import { DialogContainer } from '../components/Dialog'

export default function App({ Component, pageProps }) {
  return (
      <Context>
        <Navbar/>
        <MessageBox />
        <DialogContainer />
        <Component {...pageProps} />
      </Context>
  )
}
