import Link from 'next/link'
import style from '../styles/Navbar.module.scss'
import { useContext } from 'react';
import { UserData } from '@/components/Context';
import Text from '@/components/LanguageResolver';
import { changeLanguage } from '@/components/LanguageResolver';
import { useEffect } from 'react';

export default function Navbar() {

    const { user, setUser } = useContext(UserData);

    useEffect(() => {
        if (user.isLoaded) {
            // set dropdown value to user language
            console.log(user.language.code);
            document.getElementById('languageSelect').value = user.language.code.toString();
        }
    }, [user.isLoaded, user.language.code]);

    return (
        <>
            <div className={style.container}>
                <Link href='/'>
                    <p><Text val="navbar.home" /></p>
                </Link>
                <Link href='/play/'>
                    <p><Text val="navbar.play" /></p>
                </Link>
                <select id='languageSelect' onChange={(e) => changeLanguage(e.target.value, user, setUser)}>
                    <option value="en">English</option>
                    <option value="de">Deutsch</option>
                    <option value="pl">Polski</option>
                </select>
    
                {
                    user.isLoaded ? (
                        <Link className={style.right} href='/user/profile/'>
                        <p><Text val="navbar.profile" /></p>
                    </Link>
                        ) : (
                            <>
                            <Link className={style.right} href='/user/login/'>
                                <p><Text val="navbar.login" /></p>
                            </Link>
                            <Link className={style.right} href='/user/register/'>
                                <p><Text val="navbar.register" /></p>
                            </Link></>
                            )
                }
            </div>
        </>
        )
}